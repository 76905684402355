<template>
    <v-overlay>
        <v-progress-circular
            indeterminate
            color="primary">
        </v-progress-circular>
    </v-overlay>
</template>

<script>
export default {}
</script>