<template>
  <div>
    <Loading v-if="loading" />
    <v-card
      flat
      class="pa-3 mt-2"
      <v-form
    >
      <v-card-text
        :key="key"
        class="pt-5"
      >
        <v-row>
          <v-col
            md="12"
            cols="12"
            class="mb-4 mt-0 pb-0"
          >
            <div class="text-left">
              General information
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="mt-0 pt-0"
          >
            <v-textarea
              ref="bio"
              v-model="optionsLocal.value.bio"
              outlined
              no-resize
              counter="200"
              rows="3"
              label="My description"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="ml-0 mt-0 pt-0"
          >
            <v-select
              v-model="optionsLocal.value.languages"
              outlined
              dense
              multiple
              chips
              small-chips
              label="Communication language"
              item-text="name"
              item-value="abbr"
              :items="fields.languages"
            ></v-select>
            <v-checkbox
              v-model="optionsLocal.value.draft_enabled"
              class="mt-0"
              label="Draft enabled"
              color="success"
              true-value="enabled"
              false-value="disabled"
              :disabled="!areFieldsFilledOut"
              :hint="!areFieldsFilledOut ? 'All details need to be filled out to be available for draft' : ''"
              :persistent-hint="true"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <div class="text-left mb-4 mt-0">
              Player information
            </div>
            <v-select
              v-model="optionsLocal.value.ingameroles"
              @input="onIngameRoleInput($event)"
              outlined
              dense
              multiple
              chips
              class="mt-2"
              small-chips
              label="Role"
              item-disabled="disabled"
              item-value="name"
              item-text="name"
              :items="fields.ingameroles"
              :persistent-hint="true"
              :hint="'A maximum of 3 roles can be selected'"
            ></v-select>
            <v-select
              v-model="optionsLocal.value.availability"
              outlined
              dense
              label="Available to play"
              class="mt-2"
              :items="['Everyday - Evening','Everyday - All day','Weekends only','Events only','No idea']"
            ></v-select>
            <v-select
              v-model="optionsLocal.value.esea_placement"
              outlined
              dense
              class="mt-2"
              label="ESEA Placement"
              :items="['Not Playing ESEA','ESEA Open','ESEA Intermediate','ESEA Main','ESEA Advanced']"
            ></v-select>
            <v-text-field
              ref="team"
              v-model="optionsLocal.value.team"
              outlined
              dense
              label="Previous team"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <div class="text-left mb-4 mt-0">
              Achievements
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <!-- <div class="text-left mb-4 mt-0">
              Events
            </div>
            <v-text-field
              outlined
              dense
              label="Event name"
            ></v-text-field> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          :disabled="!optionsLocal || !optionsLocal.value || optionsLocal.value.bio.length > 200"
          @click="submitForm"
        >
          Save changes
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, watch, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Loading from '@/views/components/Loading.vue'
import axiosClient from '@/plugins/axios'

export default {
  components: {
    Loading,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { root }) {
    const key = ref(0)
    const optionsLocal = reactive({ value: JSON.parse(JSON.stringify(props.userData)) })
    if (optionsLocal.value.bio === undefined) optionsLocal.value.bio = ''
    if (optionsLocal.value.languages === undefined) optionsLocal.value.languages = []
    if (optionsLocal.value.ingameroles === undefined) optionsLocal.value.ingameroles = []

    const loading = ref(false)
    const areFieldsFilledOut = computed(
      () =>
        optionsLocal.value.bio &&
        optionsLocal.value.languages.length > 0 &&
        optionsLocal.value.ingameroles.length > 0 &&
        optionsLocal.value.availability &&
        optionsLocal.value.esea_placement &&
        optionsLocal.value.team,
    )

    watch(optionsLocal, async () => {
      await Vue.nextTick()
      if (!areFieldsFilledOut.value) optionsLocal.value.draft_enabled = 'disabled'
    })

    const onIngameRoleInput = async data => {
      await Vue.nextTick()
      if (data.includes('Any Role')) {
        optionsLocal.value.ingameroles = ['Any Role']
      }
    }

    const isIngameRoleValueDisabled = name => {
      return (
        (optionsLocal.value.ingameroles.length >= 3 || optionsLocal.value.ingameroles.includes('Any Role')) &&
        !optionsLocal.value.ingameroles.includes(name)
      )
    }
    const fields = computed(() => {
      return {
        languages: [
          { name: 'Estonian', abbr: 'ee' },
          { name: 'Latvian', abbr: 'lv' },
          { name: 'Lithuanian', abbr: 'lt' },
          { name: 'Russian', abbr: 'ru' },
          { name: 'Finnish', abbr: 'fi' },
          { name: 'Swedish', abbr: 'se' },
          { name: 'English', abbr: 'en' },
        ],
        ingameroles: [
          { name: 'Rifler', disabled: isIngameRoleValueDisabled('Rifler') },
          { name: 'In-game Leader', disabled: isIngameRoleValueDisabled('In-game Leader') },
          { name: 'AWP', disabled: isIngameRoleValueDisabled('AWP') },
          { name: 'Lurker', disabled: isIngameRoleValueDisabled('Lurker') },
          { name: 'Support', disabled: isIngameRoleValueDisabled('Support') },
          { name: 'Any Role', disabled: isIngameRoleValueDisabled('Any Role') },
          { name: 'Coach', disabled: isIngameRoleValueDisabled('Coach') },
          { name: 'Manager', disabled: isIngameRoleValueDisabled('Manager') },
          { name: 'Caster', disabled: isIngameRoleValueDisabled('Caster') },
        ],
      }
    })

    const resetForm = async () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.userData))
      delete optionsLocal.value.avatar
      delete optionsLocal.value.country
      delete optionsLocal.value.csgo_level
      delete optionsLocal.value.faceit_id
      delete optionsLocal.value.nickname
      delete optionsLocal.value.permissions
      delete optionsLocal.value.steam
      delete optionsLocal.value.elo
      delete optionsLocal.value.statistics
      delete optionsLocal.value.discord_id
      delete optionsLocal.value.twitch_id
      delete optionsLocal.value.twitch_username
      delete optionsLocal.value.twitter_id
      delete optionsLocal.value.twitter_username
      await Vue.nextTick()
      key.value += 1
    }

    const submitForm = () => {
      loading.value = true
      axiosClient({
        url: `${Vue.prototype.$API}/profile`,
        method: 'POST',
        data: {
          data: optionsLocal.value,
        },
      })
        .then(result => {
          root.vtoast.show('Profile saved!')
          props.userData.bio = optionsLocal.value.bio
          props.userData.languages = optionsLocal.value.languages
          props.userData.ingameroles = optionsLocal.value.ingameroles
          props.userData.availability = optionsLocal.value.availability
          props.userData.esea_placement = optionsLocal.value.esea_placement
          props.userData.team = optionsLocal.value.team
          props.userData.draft_enabled = optionsLocal.value.draft_enabled
          root.$store.dispatch('player_draft/reloadPages')
          root.$store.dispatch('statistics/loadPlayerDraftCount')
          setTimeout(() => (loading.value = false), 1000)
        })
        .catch(error => {
          loading.value = false
          root.vtoast.show(error.response.data)
        })
    }

    delete optionsLocal.value.avatar
    delete optionsLocal.value.country
    delete optionsLocal.value.csgo_level
    delete optionsLocal.value.faceit_id
    delete optionsLocal.value.nickname
    delete optionsLocal.value.permissions
    delete optionsLocal.value.steam
    delete optionsLocal.value.elo
    delete optionsLocal.value.statistics
    delete optionsLocal.value.discord_id
    delete optionsLocal.value.twitch_id
    delete optionsLocal.value.twitch_username
    delete optionsLocal.value.twitter_id
    delete optionsLocal.value.twitter_username

    return {
      key,
      fields,
      optionsLocal,
      areFieldsFilledOut,
      loading,
      resetForm,
      submitForm,
      onIngameRoleInput,
    }
  },
}
</script>
